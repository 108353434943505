#riskSurveys {
    margin-top: 1em;
    color: @text;

    .riskSurvey {
        order: solid #9a9a9a 1px;
        padding: 1em 1em .4em 1em;
        background-color: white;
        box-shadow: 0.1em 0.1em 0.01em #d9d7db;
        margin-top: 0.5em;
        color: black;
        justify-content: space-between;
    }

    .riskSurvey:hover {
        background-color: #d9d7db;
    }
        

    .riskSurveys{
        margin: 0 0 5em 0;
    }

    .create-risk-survey-button-holder{
        margin: 1em;
    }


    .riskSurveyIcon{
        font-size: 1em;
        margin-right: .5em;
    }

    .riskSurveyInfo{
        width: 80%;
        justify-content: flex-start;
        @media (max-width : 600px) {
            width: 60%;
        }
    }

    .riskSurveyName{
        font-size: 1.5em;
        width: 70%;
        display: inline;

        @media (max-width : 600px) {
            width: 10em;
        }
    }
    
    .riskSurveyDistrict{
        font-size: 1em;
        margin-right: .2em;
    }

    .riskSurveyCreateDate, .riskSurveyInstitutionName{
        margin: 0 2em;
        @media (max-width : 600px) {
            margin: 0;
        }
    }

    .riskSurveyTypeName, .riskSurveyCreateDate, .riskSurveyInstitutionName{
        width: 30%;

        @media (max-width : 600px) {
            width: unset;
        }
    }

    .riskSurveyHeadline{
        justify-content: space-between;
    }

    .excel{
        cursor: pointer;
        justify-content: right;
    }

    .search-bar{
        justify-content: start;
        margin-bottom: 1em;
        padding: 1em 0;
    }

    .search-fields{
        justify-content: flex-end;

        @media (max-width : 800px) {
            justify-content: flex-start;  
        }

    }

    .search-field {
        width: 30em;
        margin: .4em;

        @media (max-width : 800px) {
            width: 10em;   
        }
    }

    .search-field.search-field-small{
        width: 10em;
    }
}