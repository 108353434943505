.MuiButton-outlinedPrimary {
  color: black !important;
}
.MuiButton-outlinedPrimary:hover {
  color: white !important;
}
.MuiFab-primary {
  background-color: #a90004 !important;
}
.MuiButton-root:hover {
  color: white;
  background: #a90004 !important;
}
.MuiSelect-select {
  width: 15em !important;
}
.MuiCircularProgress-colorPrimary {
  color: #a90004 !important;
}
.MuiTextField-root {
  width: 100%;
}
.Mui-checked {
  color: #a90004 !important;
}
.material-icons {
  margin-right: 0.5em;
  width: 1.2em !important;
}
.MuiPaper-root {
  padding: 1em;
}
.MuiMenuItem-root {
  white-space: normal !important;
}
header .logo {
  font-size: 2em;
  margin-left: 2em;
}
@media (max-width: 1000px) {
  header .logo {
    font-size: 1.2em;
    margin-top: 0.4em;
    margin-left: 2.5em;
  }
}
header .logo-red {
  color: #f8f2ee;
  font-weight: bold;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
  margin-top: 0.7em;
}
header .logo-icon {
  margin-top: 0.7em;
}
header .logo-icon img {
  height: 1.4em;
  border-radius: 10em;
}
header .logo-white {
  color: white;
  margin-left: 0.2em;
}
header .product-title {
  color: #f8f2ee;
  margin-left: 1em;
  margin-top: 0.7em;
}
header .wrapper {
  justify-content: space-between;
  width: 100%;
  background-color: #3f3f3f;
  height: 6em;
}
@media (max-width: 1000px) {
  header .wrapper {
    height: 4em;
  }
}
header .link-back-login {
  padding-left: 2em;
  padding-top: 1.8em;
}
@media (max-width: 1000px) {
  header .link-back-login {
    font-size: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1.1em;
  }
}
header .back-login {
  font-size: 2em;
  color: white;
}
header .btn-logout {
  border: solid black 0.1em;
  padding: 0.5em 1em;
  color: black;
  margin: auto;
  width: 75px;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
  text-decoration: none;
  background: #f8f2ee;
  float: right;
  margin-top: 1.8em;
  margin-right: 3em;
}
@media only screen and (max-width: 1000px) {
  header .btn-logout {
    display: none;
  }
}
header .need-help {
  color: white;
  margin-top: 2.3em;
  margin-right: 1em;
  cursor: pointer;
}
@media (max-width: 1000px) {
  header .need-help {
    margin-top: 1.3em;
    display: none;
  }
}
header .need-help:hover {
  color: #a90004;
}
#toast {
  position: fixed;
  top: 7em;
  width: 30em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0.95;
  z-index: 99;
}
#toast .title {
  font-size: 1.2em;
  font-weight: bold;
  display: inline;
  margin-right: 1em;
}
#toast .toast {
  padding: 1em;
}
#toast .success {
  background-color: #61b961;
}
#toast .error {
  background-color: #ce696b;
}
#toast .message {
  display: inline;
}
#toast .close-button {
  position: absolute;
  top: 0.1em;
  right: 0.2em;
  opacity: 0.6;
}
#toast .fa {
  color: white;
  font-size: 1.2em;
}
#app-error {
  display: inline-block;
  width: 100%;
}
#app-error .error-message {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 10em;
  width: 30%;
  background-color: #ce696b;
  padding: 2em;
}
#loader {
  display: inline-block;
  width: 100%;
}
#loader .load-icon {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 2em;
  padding: 2em;
  font-size: 4em;
}
#cookie-policy .cookie-policy {
  position: fixed;
  bottom: 0;
  color: white;
  background: #989797;
  padding: 1em;
  z-index: 1;
}
#cookie-policy .cookie-policy-text {
  width: 88%;
}
.additionalData .section-block {
  border-top: 1px solid lightgrey;
}
#sidebar {
  background: #2f2e2e;
  height: 100%;
  min-height: 168em;
  top: 6em;
  position: absolute;
  border: 0px solid #000000;
}
#sidebar .module {
  white-space: pre-wrap;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  border: 0px solid #000000;
  color: #cccccc;
  height: 4em;
  text-align: left;
  padding: 1em;
  width: 15em;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#22262b+0,091324+100 */
  text-decoration: none;
  cursor: pointer;
}
#sidebar .module a:link {
  color: #cccccc;
}
#sidebar .module a:hover {
  color: white;
}
#sidebar .module.active {
  background: #f8f2ee !important;
  color: black !important;
}
#sidebar .module.active a:hover {
  color: black;
}
#sidebar .linkStyle {
  width: 13em;
}
@media (max-width: 1000px) {
  #sidebar .linkStyle {
    padding-right: 2em;
  }
}
#sidebar .complete-check {
  position: absolute;
  color: green;
  left: 8em;
  bottom: 2.1em;
  font-size: 1.8em;
}
#sidebar div {
  white-space: pre-wrap;
}
#sidebar div a:link {
  color: white;
}
#sidebar div a:hover {
  color: white !important;
}
#sidebar .showNav-bar {
  position: absolute;
  top: 0.9em;
  left: 1.5em;
  font-size: 2em;
  color: white;
}
@media (max-width: 1000px) {
  #sidebar .showNav-bar {
    top: 0.6em;
  }
}
#sidebar .mobile-nav {
  position: absolute;
  top: 4em;
  background-color: black;
  z-index: 99;
}
#sidebar .desktop {
  display: block;
}
#sidebar .tablet {
  display: none;
}
#sidebar .tablet .btn {
  height: 7em;
  font-size: 14px;
  display: block;
}
#sidebar .tablet .complete-check {
  left: 8em;
  height: 1.2em;
}
@media (max-width: 1000px) {
  #sidebar .desktop {
    display: none;
  }
  #sidebar .tablet {
    display: block;
  }
}
@media (max-width: 1000px) {
  #sidebar {
    width: 0;
    top: 0;
  }
}
.menu .opener {
  font-size: 1.5em;
  background-color: white;
  color: black;
  padding: 0.5em 1em;
  border-radius: 1.5em;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
}
.menu .opener:hover {
  color: darkgray;
}
.menu .open {
  display: none;
}
.menu-item {
  font-size: 1.2em;
  background-color: white;
  color: black;
  padding: 0.5em 1em;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
}
.menu-item:hover {
  color: darkgray;
}
#manager .label {
  color: black;
}
#riskSurveyCreate .section {
  margin-top: 1em;
}
#riskSurveyCreate .add-another {
  margin-bottom: 2em;
}
#riskSurveyCreate .addAnother {
  font-size: 0.8em;
  color: darkgray;
  margin-top: 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
}
#riskSurveyEdit .section {
  margin-top: 1em;
}
#riskSurveyEdit .add-another {
  margin-bottom: 2em;
}
#riskSurveyEdit .addAnother {
  font-size: 0.8em;
  color: darkgray;
  margin-top: 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
}
#riskSurveyDetail .menu {
  position: absolute;
  top: 7.5em;
  right: 1.5em;
}
#riskSurveyDetail .header {
  justify-content: space-between;
}
#riskSurveyDetail .actions {
  justify-content: end;
}
#riskSurveyDetail .action {
  margin-top: 1em;
  margin-right: 2em;
}
#riskSurveyDetail .btn-archive {
  margin-left: 5em;
}
@media only screen and (max-width: 1000px) {
  #riskSurveyDetail .btn-archive {
    margin-left: 0;
  }
}
#riskSurveyDetail .btn-reopen {
  margin-left: 5em;
}
.share-dialog {
  padding: 0 2em 2em 2em;
  text-align: center;
}
.share-dialog .shareEmail {
  min-width: 20em;
}
.share-dialog .share-dialog-email {
  margin-top: 1em;
}
.share-dialog .share-button-container {
  margin-top: 1em;
}
#riskSurveys {
  margin-top: 1em;
  color: black;
}
#riskSurveys .riskSurvey {
  order: solid #9a9a9a 1px;
  padding: 1em 1em 0.4em 1em;
  background-color: white;
  box-shadow: 0.1em 0.1em 0.01em #d9d7db;
  margin-top: 0.5em;
  color: black;
  justify-content: space-between;
}
#riskSurveys .riskSurvey:hover {
  background-color: #d9d7db;
}
#riskSurveys .riskSurveys {
  margin: 0 0 5em 0;
}
#riskSurveys .create-risk-survey-button-holder {
  margin: 1em;
}
#riskSurveys .riskSurveyIcon {
  font-size: 1em;
  margin-right: 0.5em;
}
#riskSurveys .riskSurveyInfo {
  width: 80%;
  justify-content: flex-start;
}
@media (max-width: 600px) {
  #riskSurveys .riskSurveyInfo {
    width: 60%;
  }
}
#riskSurveys .riskSurveyName {
  font-size: 1.5em;
  width: 70%;
  display: inline;
}
@media (max-width: 600px) {
  #riskSurveys .riskSurveyName {
    width: 10em;
  }
}
#riskSurveys .riskSurveyDistrict {
  font-size: 1em;
  margin-right: 0.2em;
}
#riskSurveys .riskSurveyCreateDate,
#riskSurveys .riskSurveyInstitutionName {
  margin: 0 2em;
}
@media (max-width: 600px) {
  #riskSurveys .riskSurveyCreateDate,
  #riskSurveys .riskSurveyInstitutionName {
    margin: 0;
  }
}
#riskSurveys .riskSurveyTypeName,
#riskSurveys .riskSurveyCreateDate,
#riskSurveys .riskSurveyInstitutionName {
  width: 30%;
}
@media (max-width: 600px) {
  #riskSurveys .riskSurveyTypeName,
  #riskSurveys .riskSurveyCreateDate,
  #riskSurveys .riskSurveyInstitutionName {
    width: unset;
  }
}
#riskSurveys .riskSurveyHeadline {
  justify-content: space-between;
}
#riskSurveys .excel {
  cursor: pointer;
  justify-content: right;
}
#riskSurveys .search-bar {
  justify-content: start;
  margin-bottom: 1em;
  padding: 1em 0;
}
#riskSurveys .search-fields {
  justify-content: flex-end;
}
@media (max-width: 800px) {
  #riskSurveys .search-fields {
    justify-content: flex-start;
  }
}
#riskSurveys .search-field {
  width: 30em;
  margin: 0.4em;
}
@media (max-width: 800px) {
  #riskSurveys .search-field {
    width: 10em;
  }
}
#riskSurveys .search-field.search-field-small {
  width: 10em;
}
#riskSurveyModule form {
  margin-bottom: 3em;
}
#riskSurveyModule .questions {
  list-style: none;
  padding-left: 0;
  color: white;
}
#riskSurveyModule .question-text {
  font-size: 1.2em;
}
#riskSurveyModule .question-answer {
  margin-right: 1em;
}
#riskSurveyModule .btn-upload {
  width: 10em;
  margin-top: 1em;
}
#riskSurveyModule .image {
  position: relative;
  margin-bottom: 2em;
  image-orientation: from-image;
  max-width: 50em;
}
#riskSurveyModule .date {
  margin-top: 1em;
  width: 17em;
}
#riskSurveyModule .date-action {
  margin-top: 1.8em;
}
#riskSurveyModule .add,
#riskSurveyModule .remove {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
#riskSurveyModule .uploaded-file-container {
  position: relative;
  width: 5em;
  margin-right: 2em;
}
#riskSurveyModule .file-list {
  margin-bottom: 1em;
}
#riskSurveyModule .uploaded-file {
  font-size: 3em;
  padding: 0.4em;
  cursor: pointer;
  border: solid 0.01em gray;
}
#riskSurveyModule .uploaded-file-file {
  padding: 0.2em 0.4em;
}
#riskSurveyModule .uploaded-file-caption {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#riskSurveyModule .delete-file {
  position: absolute;
  top: -0.7em;
  right: -0.7em;
  font-size: 1.1em;
  cursor: pointer;
  padding: 0.3em;
  background: white;
  border-radius: 1em;
}
#riskSurveyModule .uploaded-image {
  max-width: 90%;
  max-height: 50em;
  image-orientation: from-image;
}
#riskSurveyModule .delete-image {
  position: absolute;
  top: -0.7em;
  right: -0.7em;
  font-size: 1.1em;
  cursor: pointer;
  padding: 0.3em;
  background: white;
  border-radius: 1em;
}
#riskSurveyModule .upload-section {
  margin-top: 1em;
}
#riskSurveyModule .date-entry {
  margin-top: 1em;
}
#riskSurveyModule .date {
  margin-top: 1em;
  margin-right: 2em;
}
#riskSurveyModule .dates {
  list-style-type: disc;
  padding-left: 0;
  margin-top: 0.5em;
}
#riskSurveyModule .minsec-input {
  width: 10em;
  margin-right: 1em;
}
#riskSurveyModule .dropzonearea,
#riskSurveyModule .input-field {
  width: 100%;
}
#riskSurveyModule .other-text {
  width: 20em;
}
#riskSurveyCustom .dropdown {
  width: 15em;
}
#riskSurveyCustom .paper .flex-row .flex-col h2 {
  margin-top: 5px;
}
#riskSurveyCustom h2 {
  margin-top: 1em;
}
#riskSurveyCustom .MuiPaper-root {
  margin-top: 1em;
}
#riskSurveyCustom .vertical-center {
  line-height: 40px;
}
#riskSurveyCustom .long-input {
  width: 40em;
}
#riskSurveyCustom .small-input {
  width: 20em;
  margin: 0.2em 0em;
}
#riskSurveyCustom .small-input-expanded {
  width: 40em;
}
#riskSurveyCustom .template-block {
  display: block;
  width: 90%;
}
#riskSurveyCustom .textInput-block {
  display: inline-block;
  margin-right: 1em;
}
#riskSurveyCustom .textInput {
  flex-grow: 4;
}
#riskSurveyCustom .textbox-input {
  width: 100%;
}
#riskSurveyCustom .help {
  margin: 0.6em 0.5em;
}
#riskSurveyCustom .row {
  display: inline;
}
#riskSurveyCustom .template-actions {
  justify-content: space-between;
}
#riskSurveyViolation .row .col:nth-child(n+1) {
  margin-left: 1em;
}
@media (max-width: 600px) {
  #riskSurveyViolation .row .col:nth-child(n+1) {
    margin-left: 0;
  }
}
#riskSurveyViolation .violation {
  justify-content: space-between;
}
#riskSurveyViolation .violation-questionText {
  display: contents;
}
#riskSurveyViolation .violation-questionText u {
  display: contents;
}
#riskSurveyViolation .resolved-col {
  width: 15%;
}
#riskSurveyViolation .resolved-date-col {
  width: 15%;
  margin-top: 0.7em;
}
#riskSurveyViolation .violation-col {
  width: 50%;
  margin-top: 0.7em;
}
#riskSurveyViolation .notes-col {
  width: 25%;
  margin-top: 0.7em;
}
#riskSurveyViolation .action-col {
  width: 5%;
  margin-top: 0.7em;
}
#riskSurveyViolation .action-row {
  justify-content: space-between;
}
#riskSurveyViolation .location {
  width: 15em;
}
#riskSurveyViolation .notes,
#riskSurveyViolation .location-input {
  width: 100%;
}
#riskSurveyViolation .note {
  margin-left: 1em;
}
#riskSurveyViolation .edit-violaton {
  margin-bottom: 1em;
  position: relative;
}
#riskSurveyViolation .edit-close-container {
  justify-content: flex-end;
  position: absolute;
}
#riskSurveyViolation .edit-close {
  margin: 1em;
}
#riskSurveyViolation .referenceQuestionsTable {
  width: 100%;
  font-size: 0.9em;
  border: solid 1px black;
  border-collapse: collapse;
}
#riskSurveyViolation .referenceQuestionsTableDescription {
  width: 100%;
}
#riskSurveyViolation th {
  margin: 0;
  padding: 0 1em;
  border: solid 1px black;
  text-align: left;
}
#riskSurveyViolation td {
  border: solid 1px black;
  margin: 1em;
  padding: 0 1em;
}
#riskSurveyViolation .module-heading {
  margin-top: 1em;
}
#riskSurveyViolation .referenceSheet {
  height: 40em;
  overflow-y: auto;
}
#riskSurveyViolation .resolve-button {
  margin-top: 1em;
}
#riskSurveyReport {
  color: black;
}
#riskSurveyReport .print {
  cursor: pointer;
}
#riskSurveyReport .excel,
#riskSurveyReport .download {
  margin-left: 2em;
  cursor: pointer;
}
#riskSurveyReport .pdf {
  margin-left: 2em;
  cursor: pointer;
}
#riskSurveyReport .pdfs {
  margin-left: 2em;
}
#riskSurveyReport .risk-survey-bar {
  height: 1.4em;
  padding-left: 2em;
}
#riskSurveyReport .risk-survey-bar-holder {
  width: 70%;
  border: solid white 1px;
}
#riskSurveyReport .rating-label {
  position: absolute;
}
#riskSurveyReport ul {
  list-style: none;
  padding-left: 0;
  margin-top: 2em;
}
#riskSurveyReport .dates {
  padding-left: 1.3em;
  list-style-type: disc;
  margin-top: 0.5em;
}
#riskSurveyReport .riskSurveyQuestion {
  margin-bottom: 1em;
}
#riskSurveyReport .riskSurveyAssessedName {
  margin-left: 1em;
}
#riskSurveyReport .interview {
  padding: 1em 0;
}
#riskSurveyReport .interview-header {
  font-size: 1.3em;
}
#riskSurveyReport .question {
  padding: 1em 0;
}
#riskSurveyReport .answer {
  font-weight: bold;
}
#riskSurveyReport .fa-check {
  margin-left: 0.2em;
  color: red;
}
#riskSurveyReport .overview-comments {
  list-style-type: disc;
  margin-left: 2em;
  margin-top: 0.5em;
  font-size: 0.9em;
}
#riskSurveyReport .module-recommendaiton {
  margin: 2em 0;
}
#riskSurveyReport ul {
  padding-left: 3em;
  list-style-type: circle;
}
#riskSurveyReport .disclaimer {
  margin-top: 3em;
  font-size: 1em;
  color: gray;
}
#riskSurveyReport .uploaded-image {
  max-width: 90%;
  max-height: 50em;
}
#riskSurveyReport .image {
  margin-bottom: 2em;
}
@media print {
  body * {
    visibility: hidden;
  }
  #report,
  #report * {
    visibility: visible;
  }
  #report {
    position: absolute;
    left: 0;
    top: 0;
  }
}
.fileviewer-dialog .uploaded-file {
  width: 100%;
  height: 100%;
}
.fileviewer-dialog .uploaded-file-file {
  font-size: 5em;
  text-align: center;
}
.fileviewer-dialog .uploaded-file-caption {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileviewer-dialog .download-button {
  text-align: center;
  margin-bottom: 1em;
}
a {
  text-decoration: none;
}
a:hover {
  color: white;
  text-decoration: none !important;
}
html {
  min-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  color: black;
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
html,
body {
  height: 100%;
}
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  justify-content: center;
}
select.input-field {
  height: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
  box-sizing: content-box;
  width: 100%;
  color: black;
}
textarea.input-field {
  margin-top: 1em;
  margin-bottom: 1em;
  box-sizing: content-box;
  padding: 1em 0 1em 0.5em;
  height: 1em;
  width: 100%;
  color: black;
}
input.input-field {
  border-style: solid;
  border: none;
  border-color: black;
  border-width: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  height: 1em;
  box-sizing: content-box;
  padding: 1em;
  width: 90%;
  color: black;
}
.wrapper {
  width: 100%;
  background-color: #3f3f3f;
  height: 6em;
}
.content {
  min-height: 100% !important;
  width: 100%;
}
.social-wrapper {
  height: 3em;
}
.footerSection {
  width: 100%;
  background-color: #3f3f3f;
  color: black;
  font-size: 0.75em;
  height: 12em;
  margin-top: -12em;
}
.copyright {
  margin: auto;
  padding-top: 5.3em;
  width: 600px;
  text-align: center;
}
.btn-primary {
  border-radius: 10px !important;
  background: #f8f2ee !important;
  color: black;
  width: 100%;
  margin: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-style: none;
}
.btn-primary:hover {
  background: #3f3f3f;
}
.btn-holder {
  width: 100px;
  margin: auto;
}
.other-btn {
  margin-left: 2em;
}
.roundcorner-mainframe {
  margin: auto;
  height: 100%;
  width: 100%;
}
.main-container {
  min-height: 75em;
  background: #f5f5f5;
  border: 0px solid #000000;
  padding-left: 18em;
  padding-right: 2em;
  padding-bottom: 5em;
  padding-top: 1em;
  width: 100%;
}
.main-container.no-sidebar {
  padding-left: 2em;
  padding-right: 2em;
}
@media (max-width: 1000px) {
  .main-container {
    padding: 0.5em;
  }
  .main-container.no-sidebar {
    padding: 0.5em;
  }
}
h1 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
  font-size: 2em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
h2 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
  font-size: 1.5em;
}
p {
  color: black;
  line-height: 1.5em;
  letter-spacing: 0.1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding: 0.25em 0 1em;
}
.left-space {
  margin-left: 1em;
}
.paper {
  margin-bottom: 1em;
  padding: 1em;
}
.paper h2 {
  margin-top: 0;
}
.complete-check {
  width: 25px;
  position: relative;
  left: 14em;
  bottom: 1.5em;
}
.main-content {
  width: 100%;
  background: #3b4d58;
  height: 100%;
  border: 0px solid #000000;
  padding: 2em;
}
.subtle-link {
  font-size: 0.8em;
  color: darkgray;
  margin-top: 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
}
.answer-area {
  width: 852px;
  background-color: #3f3f3f;
  margin-bottom: 2em;
  padding: 1em;
  margin-top: 1em;
}
.answer-area span {
  line-height: 2em;
}
.answer-text {
  padding-left: 1em;
}
.complete-message {
  margin: auto;
  color: black;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.message-box {
  width: 325px;
  height: 80px !important;
  margin-top: 1em!important;
  margin: auto;
  padding-top: 1em;
  border-radius: 1%;
  background-color: #f5f5f5 !important;
  -webkit-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  padding-bottom: 7em!important;
  text-align: center;
}
.complete-box {
  width: 325px;
  height: 80px !important;
  margin-top: 1em!important;
  margin: auto;
  padding-top: 2em;
  border-radius: 1%;
  background-color: #f5f5f5 !important;
  -webkit-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  padding-bottom: 8em!important;
  text-align: center;
}
.message-holder {
  width: 260px;
  padding: 0;
  margin: auto;
}
.manager-nav {
  /* Change the link color to #111 (black) on hover */
}
.manager-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #2f3c48;
}
.manager-nav li {
  float: left;
}
.manager-nav li a {
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.manager-nav li a:hover {
  background-color: #000;
}
.manager-nav .inviteActive {
  background: black;
}
.manager-nav .reportActive {
  background: black;
}
.manager-nav .createActive {
  background: black;
}
.manager-nav .listActive {
  background: black;
}
.successMessage {
  font-weight: bold;
  color: #f8f2ee;
}
.errorMessage {
  font-weight: bold;
  color: black;
}
.managerLink {
  color: #f8f2ee !important;
  position: absolute;
  top: 2.3em;
}
.managerView-link {
  color: black;
  font-size: 1.5em;
}
label {
  margin-right: 0.5em;
}
select {
  height: 1.8em;
}
.section {
  margin-top: 1em;
}
.section-block {
  margin-top: 2em;
}
.header {
  font-weight: bold;
}
@media (max-width: 1000px) {
  .hide-phone {
    display: none;
  }
}
.add-another {
  margin-bottom: 2em;
}
.addAnotherOther {
  font-size: 0.8em;
  color: darkgray;
  margin-top: 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
}
.ai-generated-label {
  background-color: darkblue;
  border-radius: 0.5em;
  color: white;
  padding: 0.2em;
}
.ai-generated-warning {
  margin-top: 0.2em;
  font-size: 0.6em;
  font-weight: 300;
}
