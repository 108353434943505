#riskSurveyViolation{
    .row{
        .col:nth-child(n+1){
            margin-left: 1em;
        }

        @media (max-width : 600px) {
            .col:nth-child(n+1){
                margin-left: 0;
            }
        }
    }

    .violation {
        justify-content: space-between;
    }

    .violation-questionText{
        display: contents;
        
        u{
            display: contents;
        }
    }

    .resolved-col{
        width: 15%;
    }
    .resolved-date-col{
        width: 15%;
        margin-top: 0.7em;
    }
    .violation-col{
        width: 50%;
        margin-top: 0.7em;
    }
    .notes-col{
        width: 25%;
        margin-top: 0.7em;
    }
    .action-col{
        width: 5%;
        margin-top: 0.7em;
    }

    .action-row{
        justify-content: space-between;
    }

    .location{
        width: 15em;
    }

    .notes, .location-input{
        width: 100%;
    }

    .note{
        margin-left: 1em;
    }

    .edit-violaton{
        margin-bottom: 1em;
        position: relative;
    }

    .edit-close-container{
        justify-content: flex-end;
        position: absolute;
    }

    .edit-close{
        margin: 1em;
    }

    .referenceQuestionsTable{
        width: 100%;
        font-size: .9em;
        border: solid 1px black;
        border-collapse: collapse;
    }

    .referenceQuestionsTableDescription{
        width: 100%;
    }

    th{
        margin: 0;
        padding: 0 1em;
        border: solid 1px black;
        text-align: left;
    }
  
    td{
       border: solid 1px black;
       margin: 1em; 
       padding: 0 1em;
    }

    .module-heading{
        margin-top: 1em;
    }

    .referenceSheet{
        height: 40em;
        overflow-y:auto;
    }

    .resolve-button{
        margin-top: 1em;
    }
}