#riskSurveyCreate{
   .section{
       margin-top: 1em;
   }

   .add-another{
        margin-bottom: 2em;
    }

    .addAnother{
        font-size: .8em;
        color: @dullcolor;
        margin-top: .5em;
        margin-left: .5em;
        cursor: pointer;
    }
}