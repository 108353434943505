#riskSurveyCustom{
    .dropdown { 
      width: 15em;
    }

    .paper .flex-row .flex-col h2 {
      margin-top: 5px;
    }

    h2 {
      margin-top: 1em;
    }

    .MuiPaper-root{
      margin-top: 1em;
    }


    .vertical-center{
        line-height: 40px;
    }

    .long-input {
      width: 40em;
    }

    .small-input {
      width: 20em;
      margin: 0.2em 0em;
    }

    .small-input-expanded {
      width: 40em;
    }

    .template-block{
      display: block;   
      width: 90%;
    }

    .textInput-block{
      display: inline-block;
      margin-right: 1em;
    }

    .textInput{
      flex-grow: 4;
    }

    .textbox-input{
      width: 100%;
    }

    .help{
      margin: .6em .5em;
    }

    .row{
      display: inline;
    }

    .template-actions{
      justify-content: space-between;
    }
}