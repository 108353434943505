#riskSurveyDetail{
    .menu{
        position: absolute;
        top: 7.5em;
        right: 1.5em;
    }

    .header{
      justify-content: space-between;
    }

  .actions{
    justify-content: end;
  }

   .action{
       margin-top: 1em;
       margin-right: 2em;
   }

   .btn-archive{
       margin-left: 5em;

       @media only screen and (max-width : 1000px) {
        margin-left: 0;
      }
   
   }

   .btn-reopen{
        margin-left: 5em;
   }
}

.share-dialog{
    padding: 0 2em 2em 2em;
    text-align: center;
  
    .shareEmail{
        min-width: 20em;
    }

    .share-dialog-email{
      margin-top: 1em;
    }
  
    .share-button-container{
      margin-top: 1em;
    }
}