.MuiButton-outlinedPrimary{
    color: black !important;
}

.MuiButton-outlinedPrimary:hover{
    color: white !important;
}

.MuiFab-primary {
    background-color: @buttonHoverBackground !important;
  }

.MuiButton-root:hover {
    color: white;
    background: @buttonHoverBackground !important;
}

.MuiSelect-select{
    width: 15em !important;
}

.MuiCircularProgress-colorPrimary{
    color: @buttonHoverBackground !important;;
}

.MuiTextField-root{
    width: 100%;
}

.Mui-checked{
    color: @buttonHoverBackground !important;
}

.material-icons {
    margin-right: .5em;
    width: 1.2em !important;
}

.MuiPaper-root{
    padding: 1em;
}

.MuiMenuItem-root{
    white-space: normal !important;
}
