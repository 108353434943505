#riskSurveyModule {

    form{
        margin-bottom: 3em;
    }

    .questions {
        list-style: none;
        padding-left: 0;
        color: white;
    }

    .question-text{
        font-size: 1.2em;
    }

    .question-answer{
        margin-right: 1em;
    }

    .btn-upload{
        width: 10em;
        margin-top: 1em;
    }

    .image {
        position: relative;
        margin-bottom: 2em;
        image-orientation: from-image;
        max-width: 50em;
    }

    .date{
        margin-top: 1em;
        width: 17em;
    }

    .date-action{
        margin-top: 1.8em;
    }

    .add, .remove {
        margin-left: .5em;
        margin-right: .5em;
    }

    .uploaded-file-container{
        position: relative;
        width: 5em;
        margin-right: 2em;
    }

    .file-list{
        margin-bottom: 1em;
    }

    .uploaded-file{
        font-size: 3em;
        padding: 0.4em;
        cursor: pointer;
        border: solid 0.01em gray;
    }

    .uploaded-file-file{
        padding: 0.2em 0.4em;
    }

    .uploaded-file-caption{
        font-size: .7em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    
    }

    .delete-file{
        position: absolute;
        top: -0.7em;
        right: -0.7em;
        font-size: 1.1em;
        cursor: pointer;
        padding: 0.3em;
        background: white;
        border-radius: 1em;
    }


    .uploaded-image{
        max-width: 90%;
        max-height: 50em;
        image-orientation: from-image;
    }

    .delete-image{
        position: absolute;
        top: -0.7em;
        right: -0.7em;
        font-size: 1.1em;
        cursor: pointer;
        padding: 0.3em;
        background: white;
        border-radius: 1em;
    }

    .upload-section{
        margin-top: 1em;
    }
    .date-entry{
        margin-top: 1em;
    }

    .date{
        margin-top: 1em;
        margin-right: 2em;
    }

    .dates{
        list-style-type: disc;
        padding-left: 0;
        margin-top: .5em;
    }

    .minsec-input{
        width: 10em;
        margin-right: 1em;
    }

    .dropzonearea, .input-field{
        width: 100%;
    }

    .other-text{
        width: 20em;
    }

}
