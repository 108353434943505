#riskSurveyReport {
    color: black;

    .print{
        cursor: pointer;
    }

    .excel, .download{
        margin-left: 2em;
        cursor: pointer;
    }

    .pdf{
        margin-left: 2em;
        cursor: pointer;
    }

    .pdfs{
        margin-left: 2em;
    }

    .risk-survey-bar{
        height: 1.4em;
        padding-left: 2em;
    }

    .risk-survey-bar-holder{
        width: 70%;
        border: solid white 1px;
    }

    .rating-label {
        position: absolute;
    }

    ul{
        list-style: none;
        padding-left: 0;
        margin-top: 2em;
    }

    .dates{
        padding-left: 1.3em;
        list-style-type: disc;
        margin-top: 0.5em;
    }
    
    .riskSurveyQuestion{
        margin-bottom: 1em;
    }

    .riskSurveyAssessedName{
        margin-left: 1em;
    }

    .interview{
        padding: 1em 0;
    }

    .interview-header{
        font-size: 1.3em;
    }

    .question{
        padding: 1em 0;
    }

    .answer{
        font-weight: bold;
    }

    .fa-check{
        margin-left: .2em;
        color: red;
    }

    .overview-comments{
        list-style-type: disc;
        margin-left: 2em;
        margin-top: .5em;
        font-size: .9em;
    }

    .module-recommendaiton{
        margin: 2em 0;
    }
    
    ul {
        padding-left: 3em;
        list-style-type: circle;
    }

    .disclaimer{
        margin-top: 3em;
        font-size: 1em;
        color: gray;
    }

    .uploaded-image{
        max-width: 90%;
        max-height: 50em;
    }
    
    .image{
        margin-bottom: 2em;
    }

}

@media print {
    body * {
      visibility: hidden;
    }
    #report, #report * {
      visibility: visible;
    }

    #report {
      position: absolute;
      left: 0;
      top: 0;
    }
  }